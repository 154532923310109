import { useCallback, useEffect, useState } from "react";

import Loader from "../../loader/loader";
import api from "../../api";
import useDeviceInfo from "../../features/hooks/useDeviceInfo";
import { isAvailableOsAndBrowserForTgRedirect } from "../../helpers";
import useInvoiceService from "../services/useInvoiceService";
import useSearchParamsFixed from "../../features/hooks/useSearchParamsFixed";
import InvoiceSuccess from "./InvoicePage/InvoiceSuccess";
import useAppContext from "../../useAppContext";
import { Box, Link } from "@mui/material";
import { NonModalDialogPage } from "../../features/NonModalDialog";

interface ISuccessPaymentLocalization {
	successPayment: string;
	backToTgBot: string;
	redirectMessage: string;
	redirectLink: string;
	goBack: string;
}

export default function SuccessPaymentPage() {
	const { lang, setPageTitle, bot } = useAppContext();
	const [searchParams, setSearchParams] = useSearchParamsFixed();

	const { invoice, error, redirectToInvoicePage, getRedirectClient, computedInvoiceImage } =
		useInvoiceService(null, null, lang);

	const deviceInfo = useDeviceInfo();

	const [isLoading, setIsLoading] = useState(true);
	const [localizations, setLocalizations] = useState<ISuccessPaymentLocalization | null>(null);
	const [triedRedirect, setTriedRedirect] = useState<boolean>(false);

	const botName = bot?.username;
	const redirectTgLink = `tg://resolve?domain=${botName}`;
	const tgLink = `https://t.me/${botName}`;

	const getLocalizations = async (lang: string) => {
		const payload = {
			variables: {
				successPayment: "web app payment success message text",
				backToTgBot: "web app return to telegram button",
				redirectMessage: "web app redirected in message",
				redirectLink: "web app force redirect link text",
				goBack: "web app error callback link text",
			},
			lang,
		};
		const response = await api.localisation.getLocalisationList(payload);
		if (response.status === 200 && response.data) {
			setLocalizations(response.data);
			return;
		}
		setLocalizations({
			successPayment: "Thanks for the payment!",
			backToTgBot: "Return to Telegram",
			redirectMessage: "You will be automatically redirected in",
			redirectLink: "For immediate redirect, or if you were not redirected, click here.",
			goBack: "Go back",
		});
	};

	const onLoad = useCallback(async () => {
		try {
			if (lang) await getLocalizations(lang);
		} finally {
			setSearchParams(prev => {
				prev.delete("token");
				prev.delete("lang");
				return prev;
			});
			setIsLoading(false);
		}
	}, [lang, setSearchParams]);

	const tryRedirectToTgBot = useCallback(() => {
		if (!botName || triedRedirect) return;

		setTriedRedirect(true);
		if (isAvailableOsAndBrowserForTgRedirect(deviceInfo)) {
			try {
				window.location.href = redirectTgLink;
			} catch (err) {
				console.log(err);
			}
		}
		window.location.href = tgLink;
	}, [botName, deviceInfo, redirectTgLink, triedRedirect, tgLink]);

	useEffect(() => {
		if (window.Telegram?.WebApp.initDataUnsafe.user) {
			window.Telegram.WebApp.close();
		}
		onLoad().then();
	}, [lang, onLoad]);

	useEffect(() => {
		if (invoice?.title) setPageTitle(invoice.title);
	}, [invoice, setPageTitle]);

	useEffect(() => {
		if (invoice && invoice.status !== "payed") {
			console.log("invoice have not been payed", invoice);
			redirectToInvoicePage();
		} else {
			if (!searchParams.get("invoice_token")) {
				if (deviceInfo.info && !isLoading && !invoice?.client_redirect_url) {
					tryRedirectToTgBot();
				}
			}
		}
	}, [deviceInfo, isLoading, invoice, redirectToInvoicePage, searchParams, tryRedirectToTgBot]);

	if (error) return <div className={"text-center"}>{error}</div>;
	if (!invoice) return null;

	if (isLoading || !localizations) {
		return <Loader />;
	}

	return (
		<NonModalDialogPage
			containerProps={{
				maxWidth: "sm",
			}}
			header={localizations.successPayment}
		>
			<Box width={"100%"}>
				<Link px={3} fontSize={"small"} role={"button"} onClick={getRedirectClient(true)}>
					{localizations.goBack}
				</Link>
				{invoice.status === "payed" && (
					<InvoiceSuccess invoice={invoice} computedInvoiceImage={computedInvoiceImage} />
				)}
			</Box>
		</NonModalDialogPage>
	);
}
