import useLocalisation from "../../../hooks/localisation/useLocalisation";
import { Box, BoxProps, Button, Divider, Typography } from "@mui/material";
import useAppContext from "../../../useAppContext";
import { MouseEventHandler } from "react";
import Interweave from "../../../features/Interweave";

interface LoginOptionsStepProps extends BoxProps {
	asGuestCallback?: MouseEventHandler<HTMLButtonElement>;
}

export default function LoginOptionsStepper({ asGuestCallback, ...props }: LoginOptionsStepProps) {
	const {
		authService: { user, setShowAuthorisationModal },
		groupService: { group },
	} = useAppContext();

	const localisation = useLocalisation();

	if (user) return null;

	return (
		<Box {...props}>
			{/* <Typography variant={"body2"} color={"text.secondary"} gutterBottom mt={2}>
				<small>
					<Interweave content={group?.texts.web.auth.helper_text || ""} />
				</small>
			</Typography> */}

			<Box display={"flex"} flexDirection={"column"} gap={1}>
				<Button
					size={"small"}
					variant={"contained"}
					onClick={() => {
						setShowAuthorisationModal(true);
					}}
					sx={{ flex: 1 }}
				>
					{localisation.auth.loginRegisterButton}
				</Button>
				<Divider sx={{ mt: 2, mb: 2, color: "text.secondary" }}>
					{group?.texts?.web?.order?.or_text}
				</Divider>
				<Button
					type={"submit"}
					size={"small"}
					variant={"outlined"}
					onClick={asGuestCallback}
					sx={{ flex: 1 }}
				>
					{localisation.orders.authStepGuestSub}
				</Button>
			</Box>
		</Box>
	);
}
